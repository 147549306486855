<template>
  <div>
    <div
      style="
        background-color: rgba(247, 248, 250, 1);
        height: 20px;
        width: 100%;
      "
    ></div>

    <div class="about-box">
      <p class="About_us_en">About us</p>
      <p class="About_us_cn">关于我们</p>

      <div class="row-box">
        <p class="row">
          山东精准教育科技发展有限公司是一家专注于K12大数据精准教学解决方案研发和推广的科技型企业。总部于历史文化悠久、人文气息浓厚的“泉城”济南。
        </p>
        <p class="row">
          公司始终坚持“以人才为本”的经营理念，荟萃业界精英，致力于以大数据、人工智能等科学技术助力老师教学精准化、学生学习智能化，以实现教育减负增效之目标。
        </p>
        <p class="row">
          公司以“用先进科学技术，助教育减负增效”为己任，推出了支持大数据精准教学的校本化智能作业整体解决方案。包括：帮助学校对作业内容快速校本化改编的精品题库和校本化作业编辑系统、帮助学生在校快速采集作业数据的“去手机化”智能手持终端、帮助学生在家快速提交作业答案和采集错题、对点磨练的“精教智学App”和“精教智学”桌面应用平台等系列软硬件产品。形成了校内和校外、统一和个性作业的全样本数据采集和分析方案。我们的目标是：让老师更了解学生，以便精准施教；让学生更了解自己，以便高效学习、精准提分。
        </p>
        <p class="row">
          开放、好客的精教科技人在“泉城”济南欢迎您！并期待与您一道为学校、为孩子打造一款“精教智学”的教、学平台，为解放孩子的而学习尽一份功德！
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style scoped>
.row-box {
  margin-top: 30px;

}
.row-box  .row {
    width: 100%;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #000e18;
    letter-spacing: 0;
    line-height: 45px;
    font-weight: 400;
  }
.About_us_cn {
  font-family: PingFangSC-Semibold;
  font-size: 32px;
  color: #000e18;
  letter-spacing: 0;
  font-weight: 600;
  margin-top: 8px;
}
.About_us_en {
  font-family: PingFangSC-Semibold;
  font-size: 32px;
  color: #319bff;
  letter-spacing: 2.7px;
  font-weight: 600;
}
.about-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  margin: 0px 200px 0px 200px;
  padding: 40px;
}
p {
  text-align: left !important;
}
@media screen and (max-width: 1024px) {

  .about-box{
    margin: auto;
  }

}
</style>
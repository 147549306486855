<template>
 <div :style="{height: height+'px',width: '100%'}">1
  <embed :src="pdfurl" :style="{height: height+'px',width: '100%'}" type="application/pdf">
    <!-- <iframe :src="pdfurl" frameborder="0" :style="{height: height+'px',width: '100%'}"></iframe> -->
 </div>
</template>

<script>
export default {
  data() {
    return {
      pdfurl: '',
      height: window.innerHeight
    }
  },
    mounted() {
      console.log('About组件挂载完毕了',this.$route)
      this.pdfurl=this.$route.params.pdfurl

    },  
};
</script>

<template>
  <div class="overflow_hidden flex" :style="{ height: height + 'px', minHeight: height + 'px' }">
    <div class="pcView flex_column overflow_y height100 background_white padding10"
      :style="{ height: height + 'px', minHeight: height + 'px' }">
      <el-button class="text_align_left" style="margin: 0 " :type="pdfurl == item.pdfUrl ? 'primary' : ''" v-for="item in pdflist" :key="item.id"
        @click="pdfurl = item.pdfUrl">{{ item.remark }}</el-button>
    </div>
    <div class="pcView flex_1" :style="{ height: height + 'px', minHeight: height + 'px' }">
      <iframe :src="pdfurl" frameborder="0" style="height: 100%;width: 100%;"></iframe>
    </div>
    <div class="mobileView flex_column overflow_y height100 background_white width100 padding20">
      <el-button :type="pdfurl == item.pdfUrl ? 'primary' : ''" v-for="item in pdflist" :key="item.id"
        @click="toPdfView(item.pdfUrl, item.remark)">{{ item.remark }}</el-button>
    </div>
  </div>
</template>

<script>
import { queryImageVideos } from "@/axios/api";
export default {
  name: "Help",
  data() {
    return {
      pdflist: [],
      pdfurl: '',
      height: window.innerHeight
    }
  },
  mounted() {
    console.log(window.innerHeight)
    this._queryImageVideos()
  },
  methods: {
    _queryImageVideos() {
      queryImageVideos({ type: 80, noToken: true }).then(res => {
        if (res.status == 0) {
          this.pdflist = res.data
          this.pdfurl = res.data[0].pdfUrl
        }
      })
    },
    toPdfView(pdfurl, name) {
      console.log(pdfurl, name)
      console.log(typeof window.orientation !== 'undefined',/Android|iPhone|iPad|iPod/i.test(navigator.platform))
      if (typeof window.orientation !== 'undefined') {
        // 当前设备是移动设备 
        this.downLoad(pdfurl, name)
      } else if (/Android|iPhone|iPad|iPod/i.test(navigator.platform)) {
        // 当前设备是移动设备
        this.downLoad(pdfurl, name)
      } else {
        this.$router.push({
          name: 'PdfUrl',
          params: { pdfurl }
        });
      }
    },
    downLoad(pdfurl, name) {
      // 创建Blob对象
      const blob = new Blob([pdfurl], { type: 'application/pdf' });
      // 创建URL对象
      const url = URL.createObjectURL(blob);
      // 创建a标签
      const a = document.createElement("a");
      a.href = url;
      a.download = name+'.pdf';
      // 触发下载
      a.click();
      // 释放内存
      URL.revokeObjectURL(url);
    }
  }
};
</script>

<style scoped>
.mobileView {
  display: none;
}

@media screen and (max-width: 1024px) {
  .pcView {
    display: none !important;
  }

  .mobileView {
    display: flex;
  }
}
</style>